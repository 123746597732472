<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
const el = ref()
const emit = defineEmits(['enter', 'exit'])
const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  rootMargin: {
    type: String,
    default: '0px',
  },
})
let isInitialEmit = true
let observer
function init() {
  observer = new IntersectionObserver(
    (entries) => {
      if (entries[0]?.isIntersecting) {
        emit('enter')
      } else {
        // don't emit "exit" on load
        if (!isInitialEmit) {
          emit('exit')
        } else {
          isInitialEmit = false
        }
      }
    },
    {
      rootMargin: props.rootMargin,
    }
  )
  observer.observe(el.value)
}

function destroy() {
  if (observer) {
    observer.disconnect()
  }
}

// Lifecycle
onMounted(() => {
  init()
})

onBeforeUnmount(() => {
  destroy()
})
</script>

<template>
  <component :is="tag" ref="el" class="in-view">
    <slot />
  </component>
</template>

<style lang="scss">
  .in-view p{
    opacity: 1 !important;
    transform: none !important;
  }
  .in-view div{
    opacity: 1 !important;
    transform: none !important;
  }
</style>
